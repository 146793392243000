import type { Ref } from "vue";
import type { Order, OrderStep } from "@/types/order";
import { OrderStepEnum } from "@/types/order";

export const useBooking = () =>
  useState<Order>("booking", () => ({
    _links: {},
    current_step: OrderStepEnum.CATEGORY,
    order_steps: [],
    action_label: "",
    title: "",
    cruise_id: "",
    loading_message: {
      title: "",
      text: "",
    },
  }));

const stepLinkMapping = {
  [OrderStepEnum.CATEGORY]: "/auswahl/kategorien",
  [OrderStepEnum.CABIN]: "/auswahl/kabinen",
  [OrderStepEnum.TRANSFERS]: "/auswahl/transfer",
  [OrderStepEnum.EXTRAS]: "/auswahl/transfer",
  [OrderStepEnum.EXTRA_PROGRAMS]: "/auswahl/transfer",
  [OrderStepEnum.ADDITIONS]: "/auswahl/transfer",
  [OrderStepEnum.TRAVELERS]: "/buchung",
  [OrderStepEnum.PERSONAL_DATA]: "/auswahl/zahlungsart",
  [OrderStepEnum.CONFIRMATION]: "/buchung/pruefen",
  [OrderStepEnum.AUDIT]: "/buchung/pruefen",
  [OrderStepEnum.PAYMENT]: "/buchung/zahlungsart",
};

// maps an OrderStepEnum to a url
export const useNextStep = (step: OrderStepEnum): string => {
  return stepLinkMapping[step];
};

export const useCurrentStep = (path: string): string | null => {
  const matches = Object.entries(stepLinkMapping).filter(
    (tuple) => path === tuple[1]
  );
  return matches.length > 0 ? matches[0][0] : null;
};

export interface Selections {
  [key: string]: string | number | boolean | { [key: string]: string };
}
// this store is used to collect an transfer the selection
// of the current step back to the api .e.g the kabin selected
export const useBookingSelection: () => Ref<Selections | null> = () => {
  return useState("booking-selection", () => null);
};

export const useExtraSelection = () => {
  return useState("extra-selection", () => null);
};

// controls the loading spinner on the booking layout
export const useBookingLoading = (
  loading: null | boolean = null
): Ref<boolean> => {
  const state = useState<boolean>("booking-loading", () => false);
  if (loading !== null) {
    state.value = loading;
  }
  return state;
};

export const useBookingPending = () => {
  const bookingPending = useState<boolean>("booking-pending", () => false);

  const setPending = (state: boolean) => {
    bookingPending.value = state;
  };

  return { bookingPending, setPending };
};

export const usePreProgramSelection = ref(true);

export const useResetStep = async (step: OrderStep) => {
  const booking = useBooking();
  if (step !== null) {
    const resetData = await useGo7seasClient<Order>(
      `/orders/steps/${step.order_step}`,
      {
        method: "PATCH",
      }
    );

    booking.value = resetData as Order;

    setBookingFormId(null);
    useBookingLoading(true);
    navigateTo(step.context);
  }
};

export const useHandleSubmit = async () => {
  const booking = useBooking();
  const selected = useBookingSelection();
  const previousStep = booking.value.current_step;
  useBookingLoading(true);
  const data = await useGo7seasClient<Order>("/orders/steps", {
    method: "POST",
    body: useBookingSelection().value,
  });

  booking.value = data as Order;
  selected.value = null;

  if (
    (previousStep === OrderStepEnum.EXTRA_PROGRAMS ||
      previousStep === OrderStepEnum.TRANSFERS) &&
    (data.current_step === OrderStepEnum.TRANSFERS ||
      data.current_step === OrderStepEnum.EXTRAS)
  ) {
    return;
  }
  navigateTo(useNextStep(booking.value.current_step));
};

export const getBookingFormId = () =>
  useState<string | null>("booking-form-id");
export const setBookingFormId = (
  formId: string | null = null
): Ref<string | null> => {
  const state = useState("booking-form-id", (): string | null => null);
  state.value = formId;
  return state;
};
